<template>
  <div>
    <b-modal
      v-model="social_popup"
      centered
      hide-footer
      title="Share On Social Media Platforms"
    >
      <div class="d-flex flex-column">
        <h6 class="mx-auto">
          The link that will be shared.
        </h6>
        <b-form-input
          v-model="share_link"
          class="mt-1 mx-auto"
        />
      </div>
      <div class="d-flex flex-wrap justify-content-center p-2">
        <ShareNetwork
          v-for="(info, index) of all_share_icons"
          :key="index"
          class="mx-1"
          :network="info.network"
          :url="share_link"
          :title="
            'Check out this ' +
              current_item.position +
              ' event organised by ' +
              current_item.company +
              ' via HeadHunters HQ.'
          "
        >
          <font-awesome-icon
            :icon="info.name"
            size="3x"
            :class="info.styling"
          />
        </ShareNetwork>
      </div>
    </b-modal>

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>

    <div class="vx-row">
      <b-modal
        v-model="single_preview_popup"
        centered
        hide-footer
        title=""
      >
        <div class="">
          <b-card class="">
            <div slot="no-body">
              <img
                :src="
                  job.image_url == 'default'
                    ? require('@/assets/images/placeholder/no-image.jpg')
                    : job.image_url
                "
                alt="content-img"
                class="responsive card-img-top"
              >
            </div>

            <div class="vx-row mt-4 mx-auto w-full">
              <h5>{{ job.position }}</h5>
            </div>

            <hr>
            <h6 class="my-4">
              Job description
            </h6>
            <div class="flex">
              <p>
                {{ job.description }}
              </p>
            </div>
            <hr>
            <h6 class="my-4">
              Job requirements
            </h6>
            <div class="flex">
              <p>
                {{ job.requirements }}
              </p>
            </div>
            <hr>
            <h6 class="my-4">
              Brief description of candidate
            </h6>
            <div class="flex">
              <p>
                {{ job.type_candidate }}
              </p>
            </div>

            <hr>
            <h6 class="my-4">
              Job type
            </h6>
            <div class="vx-row flex mx-auto">
              <div
                v-for="(tag, index) in job.preferences"
                :key="index"
              >
                <b-badge
                  pill
                  variant="primary"
                >
                  {{ tag }}
                </b-badge>
              </div>
            </div>

            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Working days:
              </h6>
              <p>{{ job.work_days }}</p>
            </div>

            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Working hours:
              </h6>
              <p>{{ job.work_hours }}</p>
            </div>

            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Working location:
              </h6>
              <p>
                {{ job.work_location
                }}<span
                  v-if="job.remote"
                  class="font-bold ml-2"
                >(Working from home allowed)</span>
              </p>
            </div>
            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Salary :
              </h6>
              <p>{{ job.salary }}</p>
            </div>
            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Event Period:
              </h6>
              <p class="font-semibold">
                {{ job.cleaned_start_date }} to {{ job.cleaned_end_date }}
              </p>
            </div>
            <hr>
            <div class="flex justify-between">
              <h6 class="mt-1">
                Event time:
              </h6>
              <p class="font-semibold">
                {{ job.cleaned_start_time }} to {{ job.cleaned_end_time }}
              </p>
            </div>
            <hr>
            <div class="vx-row flex mx-auto">
              <div
                v-for="(tag, index) in job.tags"
                :key="index"
              >
                <b-badge
                  pill
                  variant="dark"
                >
                  {{ tag }}
                </b-badge>
              </div>
            </div>
            <div class="box mt-6">
              <p class="grey">
                Brought to you by :
              </p>
              <div class="flex">
                <b-avatar
                  class="mb-6 block"
                  size="40px"
                  square
                  :src="
                    job.company_image == 'default'
                      ? require('@/assets/images/placeholder/no-image.jpg')
                      : job.company_image
                  "
                />
                <h5 class="mt-4 ml-2 text-left">
                  {{ job.company }}
                </h5>
              </div>
            </div>
          </b-card>
        </div>
      </b-modal>
    </div>

    <b-modal
      v-model="group_preview_popup"
      centered
      hide-footer
      fullscreen
      class="h-screen w-full"
      title=""
    >
      <listings
        v-if="group_preview_popup"
        class="w-full"
        :group-event-id="event_id"
      />
    </b-modal>

    <!-- buttons on /participating-events -->
    <b-row
      v-if="this.$router.currentRoute.path.includes('participating')"
      class="d-flex flex-row justify-content-start align-items-center button-container"
    >
      <b-col>
        <b-button
          variant="primary"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="goBack"
        >Go Back</b-button>
      </b-col>
    </b-row>

    <!-- buttons on /events -->
    <b-row
      v-else
      class="d-flex flex-row justify-content-start align-items-center button-container"
    >
      <b-button
        to="/events-manager/edit-event"
        variant="primary"
        icon-pack="feather"
        icon="icon-edit-2"
        class="shadow-lg mx-1"
        :class="{'w-100': isMobile}"
      >Organise/Participate in an event</b-button>

      <b-button
        to="/events-manager/calendar"
        icon-pack="feather"
        icon="icon-calendar"
        variant="dark"
        class="shadow-lg mx-1 mt-1 mt-md-0"
        :class="{'w-100': isMobile}"
      >Manage Availability</b-button>

      <b-button
        icon-pack="feather"
        icon="icon-eye"
        variant="primary"
        class="shadow-lg mx-1 mt-1 mt-md-0"
        :class="{'w-100': isMobile}"
        @click="companyPage"
      >Company events page</b-button>
    </b-row>

    <b-card>
      <!-- <b-col>
          <div class="p-8">
            <div class="my-2">
              <h4
                v-if="this.$router.currentRoute.path.includes('participating')"
                class="text-center"
              >
                Jobs under the {{ participating_events_header }} event.
              </h4>
              <h4
                v-else
                class="text-center"
              >
                Events that you have created
              </h4>

              <p
                v-if="
                  this.$router.currentRoute.path.includes('participating') &&
                    !event_exist
                "
                class="text-center mt-6"
              >
                Currently there are no jobs this events event
              </p>
              <p
                v-if="
                  !this.$router.currentRoute.path.includes('participating') &&
                    !event_exist
                "
                class="text-center mt-6"
              >
                You haven't created any listings yet
              </p>
            </div>
          </div>
        </b-col> -->
      <b-row class="w-100 mx-auto mx-md-0 my-1 p-0">
        <div class="d-flex justify-content-center w-100">
          <h4
            v-if="this.$router.currentRoute.path.includes('participating')"
            class="text-center"
          >
            Jobs under the {{ participating_events_header }} event.
          </h4>
          <h4
            v-else
            class="text-center font-weight-bolder"
          >
            <b> Events that you have created or participated in </b>
          </h4>

          <!-- <p
                  v-if="
                    this.$router.currentRoute.path.includes('participating') &&
                      !event_exist
                  "
                  class="text-center mt-6"
                >
                  Currently there are no jobs this events event
                </p>
                <p
                  v-if="
                    !this.$router.currentRoute.path.includes('participating') &&
                      !event_exist
                  "
                  class="text-center mt-6"
                >
                  You haven't created any listings yet
                </p> -->
        </div>
      </b-row>

      <div
        v-if="$router.currentRoute.path.includes('participating')"
        class="d-flex mx-md-0 mb-1 p-0 justify-content-end"
      >
        <ActionButton
          :current-action="{ icon: action_icon, text: action_text, variant: action_view }"
          :action-list="actionList"
          @change-action="changeAction($event)"
          @perform-action="performAction"
        />

        <b-dropdown
          v-if="false"
          :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 180px' }`"
          class="cursor-pointer mr-md-2 float-right"
          :class="{'w-100': isMobile}"
          split
          :variant="action_view"
          :text="action_text"
          right
          icon="TrashIcon"
          icon-variant="success"
          type="filled"
          @click="performAction"
        >
          <template #button-content>
            <div class="d-flex flex-gap-md w-100">
              <feather-icon
                :icon="action_icon"
                size="18"
              />
              <span>
                {{ action_text }}
              </span>
            </div>
          </template>
          <!-- <b-dropdown-item @click="changeAction('Actions')">
            <span class="d-flex justify-content-start">
              <feather-icon
                icon="InfoIcon"
                size="18"
                class="mr-1"
                variant="primary"
              />
              <span class="w-32">Actions</span>
            </span>
          </b-dropdown-item> -->

          <b-dropdown-item
            @click="changeAction('Feature Jobs')"
          >
            <span class="">
              <feather-icon
                icon="StarIcon"
                svg-classes="h-4 w-4"
                class="mr-2"
              />
              <span class="w-32">Feature Jobs</span>
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="changeAction('Remove Featured Jobs')"
          >
            <span class="text-danger">
              <feather-icon
                icon="TrashIcon"
                svg-classes="h-4 w-4"
                class="mr-2"
              />
              <span class="w-32">Remove Featured Jobs</span>
            </span>
          </b-dropdown-item>

        </b-dropdown>
        <b-button
          class="mr-md-2 float-right"
          :class="{'w-100': isMobile}"
          variant="primary"
          @click="extractJobs"
        >Export as CSV</b-button>

      </div>
      <div
        class="mb-3"
        style=" min-height: 10vh; width: 100%; height: 100%"
      >

        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100, 500, 1000, 5000],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :rows="dataRows"
          :columns="columnDefs"
          style-class="vgt-table condensed bordered"
          compact-mode
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >

          <template
            slot="column-filter"
            slot-scope="props"
          >
              <!-- v-if="props.column.field === 'cleaned_start_date'" -->
            <span
              v-if="false"
              class="mt-2"
            >
              <flat-pickr
                v-model="cleaned_start_date_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Event Period"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>

            <span
              v-else-if="props.column.field === 'cleaned_start_time'"
              class="mt-2"
            >
              <flat-pickr
                v-model="cleaned_start_time_filter"
                :config="configTimePicker"
                class="vgt-input"
                placeholder="Filter Event Period"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>

            <span
              v-else-if="[ 'created_on', 'cleaned_start_date'].includes(props.column.field)"
              class="mt-2"
            >
              <!-- <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Added On"
                @input="(value) => updateFilters(props.column.field, value)"
              /> -->

              <b-button
                variant="primary"
                @click="showDateRangeFilter(true, props.column.field)"
              >
                Click to filter the date
              </b-button>
              <b-button
                v-if="props.column.field in serverParams.columnFilters"
                class="ml-2"
                @click="resetDateRangeFilter(true, props.column.field)"
              >Reset</b-button>
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'total_applications'">
              <CellRendererApplicants
                :params="{
                  event_id: props.row.id,
                  total_applications: props.row.total_applications
                }"
              />
            </span>

            <span v-else-if="props.column.field === 'total_pending_jobs'">
              <CellRendererPendingJobs
                :params="{
                  total_pending_jobs: props.row.total_pending_jobs,
                  event_id: props.row.id
                }"
              />
            </span>

            <span v-else-if="props.column.field === 'featured'">
              <div class="w-100 d-flex justify-content-center align-items-center">
                <font-awesome-icon
                  :icon="props.row.featured ? 'fa-solid fa-star': 'fa-regular fa-star'"
                  size="lg"
                  class="text-primary"
                />
              </div>
            </span>

            <span v-else-if="props.column.field === 'name'">
              <span
                class="font-weight-bolder"
              >{{ props.row.name }} </span>
              <br>
              <small
                class="font-weight-lighter"
              >{{ props.row.id}}</small>
            </span>

            <span v-else-if="props.column.field === 'banner_image'">
              <CellRendererImage :params="{banner_image: props.row.banner_image_url}" />
            </span>

            <span v-else-if="props.column.field === 'cleaned_start_date'">
              <CellRendererEventOn
                :params="{cleaned_start_date: props.row.cleaned_start_date,
                          cleaned_end_date: props.row.cleaned_end_date}"
              />
            </span>

            <span v-else-if="props.column.field === 'cleaned_start_time'">
              <CellRendererEventTime
                :params="{cleaned_start_time: props.row.cleaned_start_time,
                          cleaned_end_time: props.row.cleaned_end_time}"
              />
            </span>

            <span v-else-if="props.column.field === 'publication_status'">
              <CellRendererCategory :params="{publication_status: props.row.publication_status}" />
            </span>

            <span v-else-if="props.column.field === 'position'">
              <CellRendererFeaturedPositions :params="{ position: props.row.position, featured: props.row.featured}" />
            </span>

            <span v-else-if="props.column.field === 'active'">
              <CellRendererStatus :params="{active: props.row.active}" />
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <CellRendererActions
                :params="{
                  table: props.row.table,
                  publication_status: props.row.publication_status,
                  id: props.row.id,
                  data: props.row
                }"
                @refresh-records="initComponent()"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

        </vue-good-table>

      </div>

    </b-card>

  </div>
</template>
<script>
import ActionButton from '@/components/ActionButton.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { format, add, sub } from 'date-fns'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import { VueGoodTable } from 'vue-good-table'
import Listings from '@/views/event-management-system/Listings.vue'

import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import CellRendererApplicants from './cell-renderer/CellRendererApplicants.vue'
import CellRendererName from './cell-renderer/CellRendererName.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

import CellRendererEventOn from './cell-renderer/CellRendererEventOn.vue'
import CellRendererEventTime from './cell-renderer/CellRendererEventTime.vue'
import CellRendererCategory from './cell-renderer/CellRendererCategory.vue'
import CellRendererImage from './cell-renderer/CellRendererImage.vue'
import CellRendererPendingJobs from './cell-renderer/CellRendererPendingJobs.vue'
import CellRendererApproval from './cell-renderer/CellRendererApproval.vue'
import CellRendererFeaturedPositions from './cell-renderer/CellRendererFeaturedPositions.vue'

export default {
  components: {
    ActionButton,
    VueGoodTable,
    flatPickr,
    Listings,

    // Cell Renderer
    CellRendererApplicants,
    CellRendererName,
    CellRendererStatus,
    CellRendererActions,
    CellRendererEventTime,
    CellRendererCategory,
    CellRendererEventOn,
    CellRendererImage,
    CellRendererPendingJobs,
    CellRendererApproval,
    CellRendererFeaturedPositions,
  },
  mixins: [
    onResizeMixins,
  ],
  data() {
    return {
      isLoading: true,
      totalRecords: 0,
      dataRows: [],
      selectedRows: [],
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'fa-hand-pointer',
      actionList: {
        'Add Job': {
          variant: 'success',
          icon: 'fa-solid fa-plus',
        },
        'Add Job (MCF)': {
          variant: 'success',
          icon: 'fa-solid fa-plus',
        },
        'Add Featured Jobs': {
          variant: 'warning',
          icon: 'fa-solid fa-star',
        },
        'Remove Featured Jobs': {
          variant: 'danger',
          icon: 'fa-regular fa-star',
        },
      },

      columnDefs: [
        {
          label: 'Actions',
          field: 'actions',
          width: '300px',
          sortable: false,
          filter: false,
          cellRendererFramework: 'CellRendererActions',
          pinned: true,
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        // {
        //   label: 'Event ID',
        //   field: 'id',
        //   width: '300px',
        //   filterOptions: {
        //     trigger: 'enter',
        //     enabled: true,
        //     placeholder: '',
        //   },

        //   cellClass: 'd-flex justify-content-center align-items-center',
        // },
        {
          label: 'Number of Applicants',
          field: 'total_applications',
          width: '300px',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererApplicants',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Jobs Pending Approval',
          field: 'total_pending_jobs',
          width: '300px',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererPendingJobs',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Image',
          field: 'banner_image',
          width: '300px',
          filter: false,
          editable: false,
          cellRendererFramework: 'CellRendererImage',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Name',
          field: 'name',
          width: '300px',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Employer Name',
          field: 'company_name',
          width: '300px',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellClass: 'd-flex justify-content-center align-items-center',
        },

        {
          label: 'Event Period',
          field: 'cleaned_start_date',
          width: '320px',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',
            // filterValue: '01-03-2023',
          },
          cellRendererFramework: 'CellRendererEventOn',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Time',
          width: '300px',
          field: 'cleaned_start_time',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
            // filterValue: '16:35',
          },
          cellRendererFramework: 'CellRendererEventTime',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Category',
          field: 'table',
          width: '150px',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererCategory',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Status',
          field: 'active',
          width: '150px',
          sortable: false,
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererStatus',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Event Created On',
          field: 'created_on',
          width: '300px',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },

        },
      ],

      all_share_icons: [
        {
          name: ['fab', 'facebook-square'],
          styling: 'facebook-color',
          network: 'facebook',
        },
        {
          name: ['fab', 'twitter-square'],
          styling: 'twitter-color',
          network: 'twitter',
        },
        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'linkedin'],
          styling: 'linkedin-color',
          network: 'linkedin',
        },
        {
          name: ['fab', 'facebook-messenger'],
          styling: 'messenger-color',
          network: 'messenger',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color',
          network: 'telegram',
        },
      ],

      selected_events: [],
      isMounted: false,

      share_link: '',
      social_popup: false,
      event_id: '',
      resume_id: '',
      interview_time: '',
      event_visibility: 'public',
      bulk_upload: false,
      bulk_upload_group: false,
      interview_popup: false,
      popup_image: '',
      document_type: 'pdf',
      // https://api.headhuntershq.com/api/media/601853647.docx
      file_source: '',
      restrict_access: false,
      selected_company: '',
      all_companies_invited: [],
      interview_duration: 0,
      interview_location: '',
      custom_questions: '',
      application_statuses: [
        { text: 'Hired', value: 'Hired' },
        { text: 'Shortlisted', value: 'Shortlisted' },
        { text: 'Kept In View', value: 'Kept In View' },
        { text: 'Rejected', value: 'Rejected' },
      ],
      selected_application_status: '',
      application_status_popup: false,
      show_desc: {
        one: true,
        two: false,
        three: false,
      },
      interview_date_time: '',
      interview_start_time: '',
      interview_end_time: '',
      interview_date: '',
      description_manager: {},
      specified_event: {},
      inside_preview_popup: false,
      preview_popup: false,
      attendance_popup: false,
      selected_applicants: [],
      itemsPerPage: {
        jobs: 4,
        applicants: 4,
        events: 4,
      },
      view_applicants: false,
      applicants: [],
      archive_live_type: true,
      event_section_type: true,
      new_event_category: 'single',
      job_type: {
        parttime: false,
        freelance: false,
        fulltime: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
      },
      all_available_slots: [],
      filtered_slots: [],
      interview_time: '',
      event_employers: [],
      position: '',
      description: '',
      requirements: '',
      type_candidate: '',
      work_days: '',
      work_hours: '',
      salary: '',
      work_location: '',
      remote: false,
      event_type: '',
      applications: [],
      job: {},
      file_upload: '',
      popupActive: false,
      single_preview_popup: false,
      group_preview_popup: false,
      group_preview_link: '',
      popupList: false,
      popupApplicant: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      registered: [],
      current_item: {},
      item_id: '',
      events_data: [],
      select_tags: [],
      selected_event_group: '',
      participating_events_header: 'group',
      event_groups: [],
      start_date: '',
      end_date: '',
      current_user: '',
      start_time: '',
      end_time: '',
      applicant_info: {},
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
      },
      configInterviewPicker: {
        altInput: true,
        altFormat: 'F j, Y',
        enableTime: false,
        enableSeconds: true,
      },
      all_event_records: {},
      recorded_ids: [],
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      dates: [],
      inside_data: {},
      inside_event_type: '',
      inside_event_category: '',
      application_note: '',
      booking_id: '',
      current_interview_type: '',

      current_table_index: 0,
      current_applicant_event: {},
      pickerLimits: {},
      booked_slots: [],
      forbidden_emails: [],
      ems_file_popup: false,
      group_template: 'group_ems.xlsx',

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      exportingCSVIndex: null,
      exportingCSV: false,
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      configTimePicker: {
        altInput: true,
        noCalendar: true,
        enableTime: true,
      },
      cleaned_start_date_filter: null,
      cleaned_start_time_filter: null,
      created_on_filter: null,

      startEndDate: null,
      masks: {
        input: 'YYYY-MM-DD',
      },

      dateRangeFilter: false,
      dateRangeField: null,
    }
  },

  computed: {

    queriedEventItems() {
      return this.$refs.events_table
        ? this.$refs.events_table.queriedResults.length
        : this.events_data.length
    },
  },

  watch: {
    '$route.path': function (val, oldVal) {
      this.initComponent()
    },
    interview_date(val) {
      this.getSlots()
    },
    new_event_category(val) {
      this.pickerLimits = {
        enableTime: false,
        minDate: sub(new Date(), { months: 3 }),
        maxDate: add(new Date(), { years: 1 }),
      }
      this.bulk_upload = false
      this.bulk_upload_group = false
    },
    event_section_type(val) {
      this.getEvents()
    },
    archive_live_type(val) {
      this.getEvents()
    },
    selected_application_status(val) {},
    selected_event_group(val) {
      const selected_group = this.event_groups.filter(
        item => item.value === val,
      )[0]
      if (selected_group) {
        this.pickerLimits = {
          enableTime: false,
          minDate: new Date(selected_group.extra_info.start_date),
          maxDate: new Date(selected_group.extra_info.end_date),
        }
      }
    },
    // all_companies_invited: function (val) {

    //   this.addPlatformJobs()
    // },

    selected_company(val) {
      if (val.length > 0) {
        const name = val[0].split('#')[0]
        const uen_no = val[0].split('#')[1]

        this.all_companies_invited.push({
          name,
          uen_no,
          email_address: '',
          invite_sent: false,
          jobs: [],
          image_url: '',
        })
        this.selected_company = ''
      }
    },

    isMobile(val) {

    },

    startEndDate(val) {
      const column = this.dateRangeField

      const startDate = format(val.start, 'yyyy-MM-dd')
      const endDate = format(val.end, 'yyyy-MM-dd')

      const value = { from: startDate, to: endDate }
      this.updateFilters(column, value)
      this.dateRangeFilter = false
    },
  },
  mounted() {
    this.isMounted = true
    this.initComponent()
  },
  created() {

    // const { event_id } = this.$route.params || {}
    // this.$set(this, 'event_id', event_id)
  },
  destroyed() {
    
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getEvents(this.$route.params.event_id ? this.$route.params.event_id : null)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    updateFilters(field, value) {
      this.serverParams.columnFilters[field] = value
      this.updateParams(this.serverParams.columnFilters)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows

      if (!this.$isEmpty(this.selectedRows)) {
        const selectedRowsIdArray = []
        this.selectedRows.forEach(row => {
          selectedRowsIdArray.push(row.user_id)
        })

        this.serverParams.selectedRows = selectedRowsIdArray
      }
    },

    initComponent() {
      const { email_address, account_type, company_id } = this.$store.state.auth.ActiveUser
      if (email_address === 'recruiter@headhuntershq.com') {
        this.restrict_access = false
      }

      this.$store.dispatch('auth/updateEventInfo', {}).catch(err => {})

      let join_event_id = this.$route.params.event_id

      if (account_type === this.$AccountTypes.EMPLOYER) {
        this.$route.params.event_id = `${company_id}-co`
      }

      if (join_event_id) {
        if (join_event_id.startsWith('join_')) {
          join_event_id = join_event_id.replace('join_', '')
        }
      }

      const { event_id } = this.$route.params
      if (event_id) {
        this.columnDefs = [
          {
            label: 'Actions',
            field: 'actions',
            width: '150px',
            filter: false,
            sortable: false,
            editable: false,
            cellRendererFramework: 'CellRendererActions',
            pinned: true,
            checkboxSelection: false,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: false,
          },
          {
            label: 'Number of Applicants',
            field: 'total_applications',
            width: `${this.$length('Number of Applicants') / 2 + 3}ch`,
            filter: true,
            editable: false,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },

            pinned: true,

            cellRendererFramework: 'CellRendererApplicants',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Featured',
            field: 'featured',
            filter: true,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              filterDropdownItems: [
                { text: 'Featured', value: 1 },
                { text: 'Not Featured', value: 0 },
              ],
            },
            editable: false,
            width: `${this.$length('Featured') + 4}ch`,
            cellClass: 'd-flex justify-content-start align-items-start',
          },
          {
            label: 'Job Position',
            field: 'position',
            filter: true,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            editable: false,
            width: '280px',
            checkboxSelection: true,
            cellRendererFramework: 'CellRendererFeaturedPositions',
            cellClass: 'd-flex justify-content-start align-items-start',
          },
          {
            label: 'Employer Name',
            field: 'employer_name',
            filter: true,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            editable: false,
            width: '280px',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Approval Status',
            field: 'publication_status',
            filter: true,
            editable: false,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              filterDropdownItems: [
                { value: 'pending', text: 'Pending' },
                { value: 'draft', text: 'Draft' },
                { value: 'live', text: 'Live' },
              ],
            },
            width: '150px',
            cellRendererFramework: 'CellRendererApproval',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Job Status',
            field: 'active',
            filter: true,
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              filterDropdownItems: [
                { value: true, text: 'Live' },
                { value: false, text: 'Closed' },
              ],
            },
            editable: false,
            width: '150px',
            cellRendererFramework: 'CellRendererStatus',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Job Added On',
            field: 'created_on',
            width: `${this.$length('ddth mmm yyyy hh:mm') + 3}ch`,
            filter: true,
            editable: false,
            cellClass: 'd-flex justify-content-center align-items-center',
          },
        ]

        this.getEvents(event_id)
      } else {
        this.columnDefs = [
          {
            label: 'Actions',
            field: 'actions',
            width: '150px',
            filter: false,
            sortable: false,
            cellRendererFramework: 'CellRendererActions',
            pinned: true,
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          // {
          //   label: 'Event ID',
          //   field: 'id',
          //   width: '325px',
          //   filterOptions: {
          //     trigger: 'enter',
          //     enabled: true,
          //     placeholder: '',
          //   },

          //   cellClass: 'd-flex justify-content-center align-items-center',
          // },
          {
            label: 'Event Image',
            field: 'banner_image',
            width: '250px',
            filter: false,
            editable: false,
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
            cellRendererFramework: 'CellRendererImage',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Event Name',
            field: 'name',
            width: '300px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Number of Applicants',
            field: 'total_applications',
            width: '150px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            cellRendererFramework: 'CellRendererApplicants',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Jobs Pending Approval',
            field: 'total_pending_jobs',
            width: '150px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            cellRendererFramework: 'CellRendererPendingJobs',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Organiser Name',
            field: 'company_name',
            width: '300px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            cellClass: 'd-flex justify-content-center align-items-center',
          },

          {
            label: 'Event Period',
            field: 'cleaned_start_date',
            width: `${this.$length('ddst mmm - ddth mmm yyyy')}ch`,
            // type: 'date',
            // dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
            // dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
            filterOptions: {
              customFilter: true,
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              // filterValue: '01-03-2023',
            },
            cellRendererFramework: 'CellRendererEventOn',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Event Time',
            width: '180px',
            field: 'cleaned_start_time',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            cellRendererFramework: 'CellRendererEventTime',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Publication Status',
            field: 'publication_status',
            width: '150px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              filterDropdownItems: [
                { value: 'draft', text: 'Draft' },
                { value: 'published', text: 'Published' },
              ],
            },
            cellRendererFramework: 'CellRendererCategory',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Event Status',
            field: 'active',
            width: '150px',
            filterOptions: {
              trigger: 'enter',
              enabled: true,
              placeholder: '',
              filterDropdownItems: [
                { value: true, text: 'Live' },
                { value: false, text: 'Closed' },
              ],
            },
            cellRendererFramework: 'CellRendererStatus',
            cellClass: 'd-flex justify-content-center align-items-center',
          },
          {
            label: 'Event Created On',
            field: 'created_on',
            width: `${this.$length('ddth mmm yyyy hh:mm') + 5}ch`,
            type: 'date',
            dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
            dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
            tdClass: 'text-left',
            thClass: 'text-left',
            filterOptions: {
              customFilter: true,
              trigger: 'enter',
              enabled: true,
              placeholder: '',
            },
            valueFormatter: params => this.$formatDate(params.value),

          },
        ]

        this.getEvents()
      }
    },

    changeAction(text) {
      this.action_text = text
      this.action_icon = this.actionList[text].icon
      this.action_view = this.actionList[text].variant

      this.performAction()
    },
    performAction() {
      switch (this.action_text) {
        case 'Add Job':
          this.addJobSpecificEvent()
          break;

        case 'Add Job (MCF)':
          this.addJobSpecificEvent('mcf')
          break;

        case 'Add Featured Jobs':
          this.featureJobs()
          break;

        case 'Remove Featured Jobs':
          this.featureJobs()
          break;

        default:
          break;
      }
    },

    addJobSpecificEvent(source_name=null) {
      if (this.$route.name === 'participating-jobs') {
        const eventId = `${this.$route.params.event_id}_event`
        localStorage.setItem('event-id', eventId)

        const query = {
          action: 'add_job',
          step_number: 3,
        }

        if (this.$isNotEmpty(source_name)) {
          query.source_name = source_name
        }

        this.$router.push({
          name: 'edit-event',
          params: {
            event_id: `${eventId}`,
          },
          query,
        })
      }
    },

    featureJobs() {
      const selected = this.selectedRows
      if (selected.length > 0) {
        const positionNames = selected.map(job => job.position)
        const jobIDs = selected.map(job => job.id)
        let positionsHTML = '<ul class="list-group inside">'
        positionNames.forEach(position => {
          positionsHTML += `<li>${position}</li>`
        })
        positionsHTML += '</ul>'
        this.$bvModal
          .msgBoxConfirm(
            this.$htmlToVNode(this, `You are about to ${this.action_text === 'Add Featured Jobs' ? 'feature' : 'stop featuring'} the following jobs: <br> ${positionsHTML}`),
            {
              title: 'Feature Jobs',
              size: 'sm',
              okVariant: `${this.action_view}`,
              okTitle: 'Confirm',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
              message: '<div v-html="text"></div>',
            },
          )
          .then(value => {
            if (value) {
              this.confirmFeatured(jobIDs)
            }
          })
      } else {
        this.$toastDanger('Feature Jobs', 'You need to select at least one job to proceed.')
      }
    },
    confirmFeatured(ids) {
      const inputData = { feature_type: this.action_text === 'Add Featured Jobs' ? 'add' : 'remove', job_ids: ids }
      this.$http
        .put('/api/feature-jobs', inputData)
        .then(response => {
          const { success } = response.data

          if (success) {
            this.initComponent()
            this.$toastSuccess('Feature Jobs', `The selected jobs have been ${this.action_text === 'Feature Jobs' ? 'featured' : 'removed from featured list'} successfully.`)
          } else {
            this.$toastDanger('Feature Jobs', 'Could not feature the jobs. Try again later.')
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    extractJobs() {
      // retrieve data from db
      this.exportingCSV = true

      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            id: 'exportToCSVId',
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 9999999999 },
      )

      this.getEvents(this.$route.params.event_id ? this.$route.params.event_id : null)
    },
   
    editSpecificEvent(event_id) {
      this.$router.push(`/events-manager/edit-event/${event_id}`)
    },
    changeUpload() {
      this.$refs.uploadCSV.value = ''
      this.$refs.uploadCSV.click()
    },
    removeCompany(index) {
      this.all_companies_invited.splice(index, 1)
    },
    addPlatformJobs() {
      const all_data = { all_companies_invited: this.all_companies_invited }
      this.$http
        .post('/api/fill-excel', { data: all_data })
        .then(response => {
          const { success } = response.data
          const { message } = response.data
          if (success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Group Invite',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'success',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Group Invite',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    downloadTemplate() {
      const company = this.$store.state.auth.ActiveUser.company_name
      let template = ''
      if (this.new_event_category === 'group') {
        template = this.group_template
      } else {
        template = 'join_ems.xlsx'
      }

      this.$http
        .get(`/api/media/${template}`, { responseType: 'arraybuffer' })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'ems_template.xlsx')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    uploadJobs(input) {
      this.file_upload = input
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'File Attachment',
            icon: 'AlertCircleIcon',
            text: 'File has been attached successfully.',
            variant: 'warning',
            position: 'center',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    getSlots() {
      const all_blocked_times = []

      this.filtered_slots = this.all_available_slots.map(slot => ({
        text: slot,
        value: slot,
      }))

      for (const slot of this.booked_slots) {
        const date = slot.split('T')[0]
        if (date === this.interview_date) {
          const time = slot.split('T')[1].replace(':00', '')
          all_blocked_times.push(time)
        }
      }

      this.filtered_slots = this.all_available_slots.filter(item => {
        if (all_blocked_times.includes(item)) {
          return false
        }
        return true
      })

      this.filtered_slots = this.filtered_slots.map(slot => ({
        text: slot,
        value: slot,
      }))
    },
    dateChanged(val, newVal, instance) {
      instance.close()
    },
    updateSelectedSlot(slot) {
      const all_buttons = document.getElementsByClassName('b-button-border')
      all_buttons.forEach(el => {
        el.classList.remove('isActive')
      })

      this.interview_time = slot
    },

    goBack() {
      // ! when using go(-1) the columnDefs is not in the correct order
      // this.$router.go(-1)

      this.$router.push({ name: 'manage-events' })
    },

    humanFriendlyDate(date_string) {
      return format(new Date(date_string), 'E, do LLL, yyyy')
    },

    companyPage() {
      const company = this.$store.state.auth.ActiveUser.company_id

      const route = this.$router.resolve({ path: `/listings?organiser=${company}` })

      window.open(route.href, '_blank')
    },
    changeDescription(details) {
      this.description_manager = details
    },
    activatePreview(event_item) {
      // if (!this.event_section_type) {
      //   this.preview_popup = true;
      //   this.specified_event = event_item;
      // }

      if (event_item.event_category !== 'group') {
        this.job = event_item
        this.single_preview_popup = true
      } else {
        this.event_id = event_item._id
        // let route = this.$router.resolve({ path: "/events/" + event_id });
        //   this.group_preview_link = route.href
        this.group_preview_popup = true
        // window.open(route.href, "_blank");
        // this.preview_popup = true;
        // this.specified_event = event_item;
      }
    },
    openInsidePop(all_job_ids, image) {
      if (all_job_ids.length > 0) {
        this.inside_data = []

        for (const item of all_job_ids) {
          this.inside_data.push({
            _id: item,
            details: this.all_event_records[item],
          })
        }
        this.popup_image = image
        this.description_manager = this.inside_data[0].details
        this.inside_preview_popup = true
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Group events',
              icon: 'AlertCircleIcon',
              text: 'There are no jobs posted by this company. Please check later',
              variant: 'warning',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    onCopy(event_id, item) {
      if (item.event_category === 'join') {
        this.share_link = `${window.location.origin}/listing/${event_id}`
      } else if (item.event_category === 'group') {
        this.share_link = `${window.location.origin}/listings/${event_id}_g`
      } else {
        this.share_link = `${window.location.origin}/listing/${event_id}`
      }

      this.specified_event = item
      // this.copyTextToClipboard(gen_link);
      this.social_popup = true
      // this.$vs.notify({
      //   time: 10000,
      //   title: "Success",
      //   text: "Event link copied successfully",
      //   color: "success",
      //   iconPack: "feather",
      //   position: "top-right",
      //   icon: "icon-check-circle",
      // });
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },
    handleInput() {
      const text = document.getElementsByClassName(
        'input-select v-select--input',
      )[0].value
      this.event_employers = []
      this.getEmployers(text)
    },
    restoreCreds() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },
    getEmployers(query) {
      this.restoreCreds()

      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          this.event_employers = []
          if (response.data.success) {
            for (const item of response.data.data) {
              this.event_employers.push({
                text: item.text,
                value: item.value,
              })
            }
          }
        })
        .catch(error => {})
    },
    reset() {
      (this.job_type = {
        parttime: false,
        freelance: false,
        fulltime: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
      }),
      (this.position = '')
      this.select_tags = ''
      this.selected_event_group = ''
      this.all_companies_invited = []
      this.description = ''
      this.start_date = ''
      this.end_date = ''
      this.start_time = ''
      this.end_time = ''
      this.image_url = ''
      this.requirements = ''
      this.type_candidate = ''
      this.work_days = ''
      this.work_hours = ''
      this.salary = ''
      this.work_location = ''
      this.remote = false
      this.interview_duration = ''
      this.interview_location = ''
      this.event_visibility = ''
      this.custom_questions = ''
      this.event_type = ''
      this.selected_tags = []
      this.new_event_category = 'single'
    },
    downloadResume(email) {
      const company = this.$store.state.auth.ActiveUser.company_name

      this.$http
        .get(`/api/resume-file/${this.applicant_info.resume_file}`, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          if (response.data.errors) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Download Notification',
                  icon: 'AlertCircleIcon',
                  text: "Applicant hasn't uploaded their resume yet.",
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            const header = response.headers
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: header['content-type'] }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', this.applicant_info.resume_file) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch(error => {})
    },

    viewApplicantInfo(item, index) {
      this.current_table_index = index
      this.applicant_info = item
      // const file_url = "http://localhost:81/api/resume-file/"+ item.resume_file
      const file_url = `${this.$http.defaults.baseURL}/api/resume-file/${item.resume_file}`
      if (item.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = file_url
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${file_url}`
      }

      // this.popupList = false;
      this.popupApplicant = true
      const all_data = { viewed: 'Yes', booking_id: item.booking_id }
      if (item.viewed === 'No') {
        this.$http
          .put('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$refs.viewed_profile[this.current_table_index].innerText = 'Yes'
            }
          })
          .catch(error => {})
      }
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    archived_events() {
      this.getEvents()
    },
    add_record() {
      this.reset()
      this.popupActive = !this.popupActive
      this.action = 'insert'
      this.pickerLimits = {}
    },
    editEvent(item) {
      if (this.preview_popup) {
        this.preview_popup = false
      }
      const { preferences } = item
      if (preferences.includes('parttime')) {
        this.job_type.parttime = true
      }
      if (preferences.includes('parttime/fulltime')) {
        this.job_type.parttime = true
        this.job_type.fulltime = true
      }
      if (preferences.includes('fulltime/parttime')) {
        this.job_type.parttime = true
        this.job_type.fulltime = true
      }
      if (preferences.includes('fulltime')) {
        this.job_type.fulltime = true
      }
      if (preferences.includes('internship_traineeship')) {
        this.job_type.internship = true
      }
      if (preferences.includes('freelance')) {
        this.job_type.freelance = true
      }
      if (preferences.includes('contract')) {
        this.job_type.contract = true
      }
      if (preferences.includes('permanent')) {
        this.job_type.permanent = true
      }
      if (preferences.includes('temporary')) {
        this.job_type.temporary = true
      }

      if (preferences.includes('flexiwork')) {
        this.job_type.flexiwork = true
      }

      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of item.tags) {
          if (data.text === categ) {
            selected_tags.push(data.value)
          }
        }
      }
      this.action = 'update'
      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      this.start_date = item.start_date
      this.end_date = item.end_date
      this.start_time = item.start_time
      this.end_time = item.end_time
      this.select_tags = selected_tags
      this.requirements = item.requirements
      this.type_candidate = item.type_candidate
      this.work_days = item.work_days
      this.work_hours = item.work_hours
      this.salary = item.salary
      this.work_location = item.work_location
      this.remote = item.remote
      this.event_type = item.event_type
      this.custom_questions = item.questions
      this.all_companies_invited = item.all_companies_invited
      this.new_event_category = item.event_category
      this.interview_duration = item.interview_duration
      this.interview_location = item.interview_location
      this.event_visibility = item.event_visibility
      this.selected_event_group = item.selected_event_group
      this.image_url = item.image_url ? item.image_url : 'default'
      this.popupActive = true
    },
    delete_event() {
      
      const all_data = this.current_item
      all_data.data_id = this.item_id
      all_data.type = 'delete'

      this.$http
        .put('/api/events', { data: all_data })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Event Manager',
                icon: 'AlertCircleIcon',
                text: 'Your event has been closed successfully',
                variant: 'success',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.getEvents()
        })
        .catch(error => {})
    },
    confirmDeleteRecord(item) {
      this.$bvModal
        .msgBoxConfirm(
          `You are about to close the following event : \n ${item.position}`,
          {
            title: 'Confirm Closure',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Close Event',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.delete_event()
          }
        })
    },
    sendInvite(details) {
      const all_data = { ...details, _id: this.item_id }
      this.$http
        .post('/api/send-invite', { data: all_data })
        .then(response => {
          const { success } = response.data
          const { message } = response.data
          if (success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Group Invite',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'success',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
            const objIndex = this.all_companies_invited.findIndex(
              obj => obj.uen_no == details.uen_no,
            )
            this.all_companies_invited[objIndex].invite_sent = true
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Group Invite',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    employerEventManager() {
      this.columnDefs = [
        {
          label: 'Actions',
          field: 'actions',
          editable: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          pinned: true,
          width: 250,
          cellRendererFramework: 'CellRendererActions',
        },
        {
          label: 'Number of Applicants',
          field: 'no_applicants',
          filter: true,
          width: 210,
          editable: false,

          cellRendererFramework: 'CellRendererApplicants',
          cellClass: 'd-flex justify-content-center align-items-center',
        },

        {
          label: 'Event Name',
          field: 'organiser',
          filter: true,
          editable: false,
          width: 210,
          cellClass: 'd-flex justify-content-center align-items-center',
        },

        {
          label: 'Job Position',
          field: 'position',
          editable: false,
          width: 350,
          checkboxSelection: false,
          cellClass: 'd-flex justify-content-start align-items-start',
        },
        {
          label: 'Employer Name',
          field: 'company',
          filter: true,
          editable: false,
          width: 350,
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Approval Status',
          field: 'approved',
          filter: true,
          editable: false,
          width: 160,
          cellRendererFramework: 'CellRendererApproval',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Job Status',
          field: 'active',
          filter: true,
          editable: false,
          width: 160,
          cellRendererFramework: 'CellRendererStatus',
          cellClass: 'd-flex justify-content-center align-items-center',
        },
        {
          label: 'Job Added On',
          field: 'created_on',
          width: '320px',
          filter: true,
          editable: false,
          cellClass: 'd-flex justify-content-center align-items-center',
        },
      ]
    },
    getEvents(event_id = undefined) {
      return new Promise((resolve, reject) => {
        this.isLoading = true
        this.restoreCreds()
        const { email_address } = this.$store.state.auth.ActiveUser
        this.current_user = email_address
        this.events_data = []
        let expected_type = 'single'
        if (!this.event_section_type) {
          expected_type = 'group'
        }
        this.event_groups = []
        const all_event_group = {}
        let show_archive = false
        show_archive = !this.archive_live_type

        let baseUrl = '/api/events'
        if (event_id) {
          baseUrl = `/api/events/${event_id}/jobs`
        }

        if (this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
          this.serverParams.extractCSV = true

          if (!this.$isEmpty(this.selectedRows)) {
            const selectedRowsIdArray = []
            this.selectedRows.forEach(row => {
              selectedRowsIdArray.push(row.id)
            })

            this.serverParams.selectedRows = selectedRowsIdArray
          }
        }

        let query = ''
        query = `?params=${JSON.stringify(this.serverParams)}`
        this.$http
          .get(`${baseUrl}${query}`, {
            onDownloadProgress(progressEvent) {
              const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
              const parent = document.querySelector('#exportToCSV')
              if (parent) {
                parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
              }
            },
          })
          .then(response => {
            if (this.exportingCSV) {
              const FILE = window.URL.createObjectURL(new Blob([response.data]))

              const docUrl = document.createElement('a')
              docUrl.href = FILE
              docUrl.setAttribute('download', response.headers.filename)
              document.body.appendChild(docUrl)
              docUrl.click()

              this.exportingCSV = false
              this.serverParams.extractCSV = false
            } else {
              if (event_id) {
                this.dataRows = response.data.jobs
              } else {
                this.dataRows = response.data.events
                this.getBannerImages()
              }
  
              this.totalRecords = response.data.totalRecords
  
              // this.$store.commit('auth/CREATE_APPLICANTS_DATA', this.registered
              // this.$store.commit('auth/CREATE_EVENTS_DATA', this.events_data)
              if (this.dataRows && this.dataRows.length > 0) {
                this.event_exist = true
              } else {
                this.event_exist = false
              }
            }

            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${100}%;`

              setTimeout(() => {
                this.$toast.dismiss(this.exportingCSVIndex)
              }, this.$longestTimeout)
            }

            this.isLoading = false
            resolve()
          })
          .catch(error => {
            console.log(error)
            this.$handleErrorResponse(error)
            reject()
          })
      })
    },
    getBannerImages() {
      this.dataRows.forEach((row, index) => {
        this.$generateDownloadUrl(row.banner_image)
          .then(res => {
            this.$set(this.dataRows[index], 'banner_image_url', res)
          })
      })
    },
    getPreferences() {
      const preferences = []
      if (this.job_type.internship_traineeship) {
        preferences.push('internship_traineeship')
      }
      if (this.job_type.freelance) {
        preferences.push('freelance')
      }
      if (this.job_type.fulltime) {
        preferences.push('fulltime')
      }
      if (this.job_type.parttime) {
        preferences.push('parttime')
      }
      if (this.job_type.temporary) {
        preferences.push('temporary')
      }
      if (this.job_type.contract) {
        preferences.push('contract')
      }
      if (this.job_type.permanent) {
        preferences.push('permanent')
      }
      if (this.job_type.flexiwork) {
        preferences.push('flexiwork')
      }

      return preferences
    },

    extract_values(edit_type) {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }

      const preferences = this.getPreferences()

      const all_data = {
        position: this.position,
        description: this.description,
        start_date: this.start_date,
        end_date: this.end_date,
        start_time: this.start_time,
        end_time: this.end_time,
        tags: selected_tags,
        image_url: this.image_url,
        preferences,
        requirements: this.requirements,
        type_candidate: this.type_candidate,
        work_days: this.work_days,
        work_hours: this.work_hours,
        salary: this.salary,
        work_location: this.work_location,
        remote: this.remote,
        all_companies_invited: this.all_companies_invited,
        interview_duration: this.interview_duration,
        interview_location: this.interview_location,
        event_visibility: this.event_visibility,
        questions: this.custom_questions,
        event_type: this.event_type,
        event_category: this.new_event_category,
        selected_event_group: this.selected_event_group,
        show: true,
        archive: false,
      }
      if (edit_type === 'insert') {
        all_data.show = true
        all_data.archive = false
      }

      return all_data
    },
    showBulk() {
      this.ems_file_popup = true

      if (this.bulk_upload_group) {
        this.new_event_category = 'group'
        this.bulk_upload_group = true
      } else {
        this.new_event_category = 'join'
        this.bulk_upload = true
      }
    },

    emsUpload() {
      const input = this.file_upload
      const { email } = this.$store.state.auth.ActiveUser

      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}
        const fd = new FormData()
        fd.append('event_visibility', this.event_visibility)
        fd.append('event_category', this.new_event_category)
        fd.append('event_group', this.selected_event_group)

        fd.append('start_date', this.start_date)
        fd.append('end_date', this.end_date)
        fd.append('start_time', this.start_time)
        fd.append('end_time', this.end_time)

        fd.append('file', input.target.files[0])
        delete this.$http.defaults.headers.common.Authorization
        this.$http
          .post(`/api/upload-ems/${email}`, fd)
          .then(response => {
            if (response.data.errors.length > 0) {
              this.popupActive = false
              this.$bvModal
                .msgBoxConfirm(response.data.errors.join('\n'), {
                  title: 'Confirm Closure',
                  size: 'sm',
                  okVariant: 'danger',
                  okTitle: 'Errors detected',
                  cancelTitle: 'Cancel',
                  cancelVariant: 'outline-secondary',
                  hideHeaderClose: false,
                  centered: true,
                })
                .then(value => {
                  if (value) {
                    this.this.showBulk()
                  }
                })
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Group Invite',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              // this.$refs.uploadCSV.value = "";
              this.file_upload = ''
              this.reset()
              this.getEvents()
                .then(() => {
                  //
                })
              if (this.$route.params.event_id) {
                this.$router.push('/events-manager/events')
              }
            }
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'File Upload',
                  icon: 'AlertCircleIcon',
                  text: 'File upload failed. Try again later.',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            // this.$refs.uploadCSV.value = "";
            this.file_upload = ''
          })

        this.ems_file_popup = false
        this.popupActive = false
      }
    },

    checkFilledFields() {
      let proceed = true
      let message = ''

      if (
        this.new_event_category == 'single'
        || this.new_event_category == 'join'
      ) {
        if (this.position.length < 3) {
          message += 'A valid job position title is required\n'
        }
        if (this.description.length < 10) {
          message += 'A valid job description is required\n'
        }
        if (this.requirements.length < 10) {
          message += 'A valid job requirement is required\n'
        }
        if (this.work_days.length < 3) {
          message += 'A valid working days is required\n'
        }
        if (this.work_hours.length < 3) {
          message += 'A valid working time is required\n'
        }
        if (this.salary.length < 3) {
          message += 'A valid job salary is required\n'
        }
        if (this.event_type.length < 3) {
          message += 'You need to specify the interview type\n'
        }

        if (this.getPreferences().length < 1) {
          message += 'You need to select at least one job type\n'
        }

        if (this.new_event_category == 'single') {
          if (this.start_date.length < 3) {
            message += 'A valid start date is required\n'
          }
          if (this.end_date.length < 3) {
            message += 'A valid end date is required\n'
          }
          if (this.start_time.length < 3) {
            message += 'A valid start time is required\n'
          }
          if (this.end_time.length < 3) {
            message += 'A valid end time is required\n'
          }
        }

        if (this.new_event_category == 'join') {
          if (this.selected_event_group.length < 3) {
            message += 'Choose your event group\n'
          }
        }
      } else if (this.new_event_category == 'group') {
        if (this.position.length < 3) {
          message += 'A valid event group name is required\n'
        }
        if (this.event_visibility.length < 3) {
          message += 'You need to select the event type\n'
        }
        if (this.start_date.length < 3) {
          message += 'A valid event start date is required\n'
        }
        if (this.end_date.length < 3) {
          message += 'A valid event end date is required\n'
        }
        if (this.start_time.length < 3) {
          message += 'A valid event start time is required\n'
        }
        if (this.end_time.length < 3) {
          message += 'A valid event end time is required\n'
        }
      }

      if (message.length > 2) {
        proceed = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Missing Event Data',
              icon: 'AlertCircleIcon',
              text: message,
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }

      return proceed
    },
    // addEvent() {
    //   if (this.bulk_upload) {
    //     if (this.new_event_category === 'join') {
    //       if (this.selected_event_group.length < 3) {
    //         const message = 'Choose your event group'
    //         this.$toast(
    //           {
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Missing Event Data',
    //               icon: 'AlertCircleIcon',
    //               text: message,
    //               variant: 'danger',
    //               position: 'center',
    //             },
    //           },
    //           { timeout: this.$longestTimeout },
    //         )
    //       } else {
    //         this.emsUpload()
    //       }
    //     }
    //   } else {
    //     const result = this.checkFilledFields()
    //     if (result) {
    //       const all_data = this.extract_values()
    //       this.$http
    //         .post('/api/event', { data: all_data })
    //         .then(response => {
    //           const { success } = response.data

    //           this.selected_event_group = response.data.data
    //           if (all_data.event_category === 'group' && this.file_upload) {
    //             this.emsUpload()
    //           } else {
    //             if (success) {
    //               this.$toast(
    //                 {
    //                   component: ToastificationContent,
    //                   props: {
    //                     title: 'Event Manager',
    //                     icon: 'AlertCircleIcon',
    //                     text: response.data.message,
    //                     variant: 'success',
    //                     position: 'center',
    //                   },
    //                 },
    //                 { timeout: this.$longestTimeout },
    //               )
    //             } else {
    //               this.$toast(
    //                 {
    //                   component: ToastificationContent,
    //                   props: {
    //                     title: 'Event Manager',
    //                     icon: 'AlertCircleIcon',
    //                     text: response.data.message,
    //                     variant: 'success',
    //                     position: 'center',
    //                   },
    //                 },
    //                 { timeout: this.$longestTimeout },
    //               )
    //             }
    //             this.reset()
    //             this.getEvents()
    //             if (this.$route.params.event_id) {
    //               this.$router.push('/events-manager/events')
    //             }
    //           }
    //         })
    //         .catch(error => {})

    //       this.popupActive = false
    //     }
    //   }
    // },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        const outer_this = this
        const proceed_with_upload = true
        let fd = new FormData()
        reader.onload = e => {
          const image = new Image()
          // Set the Base64 string return from FileReader as source.
          image.src = e.target.result
          image.onload = function () {
            const { height } = this
            const { width } = this
            if (height < 400 || width < 600) {
              outer_this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Image size error',
                    icon: 'AlertCircleIcon',
                    text: 'The image you have uploaded is too small. The minimum accepted width and height is 600px and 400px respectively.',
                    variant: 'danger',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              fd = new FormData()
              return
            }
            if (width < height) {
              outer_this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Image size error',
                    icon: 'AlertCircleIcon',
                    text: 'Please make sure the image width is larger than its height.',
                    variant: 'danger',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              fd = new FormData()
            }
          }
        }

        fd.append('file', input.target.files[0])
        // delete this.$http.defaults.headers.common["Authorization"];
        this.isLoading = true
        this.$http
          .post('/api/event-image', fd)
          .then(response => {
            if (response.data.success) {
              this.image_url = response.data.image
              this.isLoading = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Picture Upload Status',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            this.isLoading = false
          })
          .catch(error => {})

        const { token } = this.$store.state.auth.ActiveUser
        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        reader.readAsDataURL(input.target.files[0])
      }
    },
    onClickCloseButton() {
      this.$emit('closePopup')
    },


    showDateRangeFilter(show, field) {
      this.dateRangeFilter = show
      this.dateRangeField = field
    },
    resetDateRangeFilter(show, field) {
      this.dateRangeField = field
      delete this.serverParams.columnFilters[field]
      this.startEndDate = null
      this.updateParams({})
    },
  },
}
</script>

<style lang="scss" scoped>
.ag-grid-table {
  // height: auto !important;
}

.card-body {
  // reduce gap on b-card
  padding-bottom: 0;
  padding-top: 1rem;
  padding-inline: 1rem;
}
.button-container {
  margin-bottom: 1rem;
}
</style>
