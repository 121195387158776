<template>
  <div>
    <p class="p-0 mb-0">
      {{ params.position }}
    </p>
    <b-badge
      v-if="params.featured"
      variant="primary"
      class=""
    >
      Featured
    </b-badge>
  </div>

</template>

<script>
export default {
  name: 'CellRendererFeaturedPositions',
  props: {
    params: Object,
  },
}
</script>
